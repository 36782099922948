var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('PageHeader', {
    attrs: {
      "title": "Notifications"
    }
  }), _vm.loadingAction.getNotifications && _vm.sortedNotifications.length === 0 ? _c('Spinner') : _vm._e(), _vm._l(_vm.sortedNotifications, function (notification) {
    return _c('router-link', {
      key: notification._id,
      staticClass: "block block-rounded block-link-shadow mb-4",
      class: {
        'text-dark': !notification.isSeen,
        'text-black-25': notification.isSeen
      },
      attrs: {
        "data-cy": notification._id,
        "to": notification.route
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.onClickNotification(notification._id);
        }
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('p', {
      staticClass: "font-size-sm text-muted float-sm-right mb-1"
    }, [_c('em', [_vm._v(_vm._s(_vm._f("timeago")(notification.createdAt)))])]), _c('p', {
      staticClass: "mb-0"
    }, [_c('i', {
      staticClass: "fa fa-fw mr-1",
      class: notification.icon
    }), _vm._v(" "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(notification.content)
      }
    })])])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }