<template>
  <div>
    <div class="content content-full content-boxed">
      <PageHeader title="Notifications" />
      <Spinner v-if="loadingAction.getNotifications && sortedNotifications.length === 0" />
      <router-link
        v-for="notification in sortedNotifications"
        :key="notification._id"
        :data-cy="notification._id"
        class="block block-rounded block-link-shadow mb-4"
        :class="{ 'text-dark': !notification.isSeen, 'text-black-25': notification.isSeen }"
        :to="notification.route"
        @click.native="onClickNotification(notification._id)"
      >
        <div class="block-content block-content-full">
          <p class="font-size-sm text-muted float-sm-right mb-1">
            <em>{{ notification.createdAt | timeago }}</em>
          </p>
          <p class="mb-0"><i class="fa fa-fw mr-1" :class="notification.icon"></i> <span v-html="notification.content"></span></p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader';
import Spinner from '@/components/Spinner';

export default {
  name: 'Notifications',
  components: {
    PageHeader,
    Spinner
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loadingAction: 'user/loadingAction',
      notifications: 'user/notifications'
    }),
    sortedNotifications() {
      return this.notifications.data;
    }
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    ...mapActions({
      getNotifications: 'user/getNotifications',
      seenNotification: 'user/seenNotification'
    }),
    onClickNotification(notificationId) {
      this.seenNotification({ id: notificationId });
    }
  }
};
</script>
